























import Vue from 'vue';
import SDialog from '@stratumfive/ui-baseplate/src/components/SDialog/SDialog.vue';

export default Vue.extend({
  name: 'HelpText',
  components: {
    SDialog,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    content: {
      required: true,
      type: String,
    },
    color: {
      required: false,
      type: String,
      default: 'info lighten-1',
    },
  },
});
