






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({

})
export default class CardTitle extends Vue {
  @Prop() private icon: string;

  @Prop() private label: string;

  @Prop() private colour: string;

  @Prop() private errors: object[];

  private get setColour() {
    const selectedColour = {
      bg: 'card__label--grey',
      icon: 'icon--accent',
    };
    switch (this.colour) {
      case 'primary':
        selectedColour.bg = 'card__label--primary';
        break;
      case 'accent':
        selectedColour.bg = 'card__label--accent';
        selectedColour.icon = 'icon--white';
        break;
      case 'white':
        selectedColour.bg = 'card__label--white';
        break;
      default:
        break;
    }
    return selectedColour;
  }
}
