

















































import { Component, Vue, Prop } from 'vue-property-decorator';
import CardTitle from '@/components/shared/CardTitle.vue';
import HelpText from '@/components/shared/HelpText.vue';

@Component({
  components: {
    CardTitle,
    HelpText,
  },
})
export default class CanvasCard extends Vue {
  @Prop({ required: true })
  private title: string;

  @Prop() private flush: boolean;

  @Prop() private icon: string;

  @Prop() private colour: string;

  @Prop() private theme: string;

  @Prop() private description: string;

  @Prop() private minify: boolean;

  @Prop() private errors: object;

  private minimized;

  constructor() {
    super();
    this.minimized = false;
  }

  private get className() {
    return `title-${this.title.replace(/ +/g, '-').toLowerCase()}`;
  }

  private get useTheme() {
    return this.theme ? this.theme : 'none';
  }

  private get showIcon(): boolean {
    return !!this.icon;
  }
}
